import React, { useEffect, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
import Reveal from "./motion/Reveal";
import RevealX from "./motion/RevealX";
const SliderVideo = () => {
  const ref = useRef();
  const ref2 = useRef();

  // useEffect(() => {
  //   ref.current.play();
  //   ref2.current.play();
  // }, []);

  return (
    <div className="relative">
      <div
        dir="rtl"
        className="absolute text-[14px] left-0 w-full top-0 p-2 bg-white/80 z-40 flex justify-between md:hidden"
      >
        <span className="flex gap-1 items-center">
          <img src="/logo.png" alt="Dr Gold Logo" className="w-8" />
          نصب وب اپلیکیشن (PWA) دکتر گلد
        </span>
        <Link
          to={"/install-app"}
          className="bg-[rgb(109,90,56)] px-3 py-1 text-white text-[12px]"
        >
          نصب
        </Link>
      </div>
      <Swiper
        style={{ direction: "ltr" }}
        effect="fade"
        autoplay={{
          delay: 6000,
        }}
        modules={[Autoplay, EffectFade]}
        className="w-full"
      >
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              playsInline
              src="/video/2.mp4"
              ref={ref}
              muted
              autoPlay
              loop
              webkit-playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/30"></span>

            <div
              dir="rtl"
              className="absolute top-[40%] md:top-[45%] translate-y-[-50%] right-[0] lg:right-[11%] w-full z-10  text-right text-white"
            >
              <RevealX delay={0.5}>
                <div className=" grid px-8 md:grid-cols-3 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] pe-5">
                      دکتر گلد
                    </h2>
                    <p>
                      {" "}
                      تمام محصولات با عشق و بصورت شخصی سازی شده برای شما عزیزان
                      طراحی و ساخته شده است
                      <br />
                      با عشق ببینید، با عشق هدیه دهید و با عشق استفاده کنید
                    </p>
                    <Link to={"/products"} className=" slider-btn1 mt-4">
                      مشاهده محصولات
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              playsInline
              src="/video/1.mp4"
              ref={ref}
              muted
              autoPlay
              loop
              webkit-playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/30"></span>

            <div
              dir="rtl"
              className="absolute top-[40%] md:top-[45%] translate-y-[-50%] right-[0] lg:right-[11%] w-full z-10  text-right text-white"
            >
              <RevealX delay={0.5}>
                <div className=" grid px-8 md:grid-cols-3 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] pe-5">
                      دکتر گلد
                    </h2>
                    <p>
                      {" "}
                      تمام محصولات با عشق و بصورت شخصی سازی شده برای شما عزیزان
                      طراحی و ساخته شده است
                      <br />
                      با عشق ببینید، با عشق هدیه دهید و با عشق استفاده کنید
                    </p>
                    <Link to={"/products"} className=" slider-btn1 mt-4">
                      مشاهده محصولات
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              playsInline
              src="/video/3.mp4"
              autoPlay
              ref={ref2}
              muted
              loop
              webkit-playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/30"></span>

            <div
              dir="rtl"
              className="absolute top-[40%] md:top-[45%] translate-y-[-50%] right-[0] lg:right-[11%] w-full z-10  text-right text-white"
            >
              <RevealX delay={0.5}>
                <div className=" grid px-8 md:grid-cols-3 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] pe-5">
                      دکتر گلد
                    </h2>
                    <p>
                      {" "}
                      تمام محصولات با عشق و بصورت شخصی سازی شده برای شما عزیزان
                      طراحی و ساخته شده است
                      <br />
                      با عشق ببینید، با عشق هدیه دهید و با عشق استفاده کنید
                    </p>
                    <Link to={"/products"} className=" slider-btn1 mt-4">
                      مشاهده محصولات
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliderVideo;
