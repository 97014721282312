import React, { useContext, useEffect, useState } from "react";
import { httpReauest } from "../utils/httpRequest";
import ProductCard from "./common/ProductCard";
import Loading from "./common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const Products = ({ formik }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState();
  const [hasmore, sethasmore] = useState(false);

  useEffect(() => {
    // if (!fit) {
    //   // getData();
    // }
  }, []);

  useEffect(() => {
    if (formik.values.min_price !== 0 && formik.values.max_price !== 150) {
      const timer = setTimeout(() => {
        newfetchData();
      }, 2000); // 3 ثانیه
  
      // پاک‌سازی تایمر در هنگام تغییر formik
      return () => {
        clearTimeout(timer);
      };
    }
   
  }, [formik.values.min_price, formik.values.max_price]);

  useEffect(() => {
    newfetchData();
  }, [formik.values.category]);

  async function getData() {
    setLoading(true);
    await httpReauest("GET", "/product", {}, {}).then(({ data }) => {
      setProducts(data?.data);
    });
    setLoading(false);
  }

  async function newfetchData() {
    setLoading(true);
    const page = 1;
    await httpReauest(
      "GET",
      `/product?page=${page}${
        formik.values.category ? `&category=${formik.values.category}` : ""
      }${
        formik.values.min_price ? `&minPrice=${formik.values.min_price}` : ""
      }${
        formik.values.max_price && formik.values.max_price != 150
          ? `&maxPrice=${formik.values.max_price}`
          : ""
      }`,
      {},
      {}
    ).then(({ data }) => {
      setProducts(data.data);
      setLoading(false);

      if (data?.data?.length == 10) {
        sethasmore(true);
      } else {
        sethasmore(false);
      }
    });
  }

  async function fetchData() {
    const page = products.length / 10 + 1;
    console.log(page);
    await httpReauest(
      "GET",
      `/product?page=${page}${
        formik.values.category && `&category=${formik.values.category}`
      }`,
      {},
      {}
    ).then(({ data }) => {
      setProducts((cur) => [...cur, ...data.data]);

      if (data?.data?.length < 10) {
        sethasmore(false);
      }
    });
  }

  return (
    <InfiniteScroll
      dataLength={products?.length ? products?.length : 10} //This is important field to render the next data
      next={fetchData}
      hasMore={hasmore}
      loader={"Loading..."}
    >
      {loading ? (
        <Loading />
      ) : (
        <div
          className={
            "grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 p-[.5rem] lg:p-[1.5rem]"
          }
        >
          {products?.map((e) => (
            <span>
              <ProductCard data={e} />
            </span>
          ))}
        </div>
      )}
    </InfiniteScroll>
  );
};

export default Products;
