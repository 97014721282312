import React, { useState } from "react";
import {
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import copy from "clipboard-copy";
import { Link } from "react-router-dom";
import {
  BsEnvelope,
  BsInstagram,
  BsTelegram,
  BsWhatsapp,
} from "react-icons/bs";
import { FaAngleLeft } from "react-icons/fa6";

const ShareModal = ({ url, setShare, setstop, desktop }) => {
  const [copied, setCopied] = useState();

  if (desktop) {
    return (
      <div className="p-3">
        <div>
          <h2 className="text-[20px] font-[700] text-right">اشتراک گذاری</h2>
        </div>
        <div>
          <div dir="ltr" className="flex gap-2 items-center w-full">
            <input
              value={url}
              disabled
              className="rounded border h-[42px] px-2 w-full"
            />
            <button
              onClick={() => {
                setCopied(true);
                copy(url);
              }}
              className="border rounded font-bold px-3 py-2 my-4 whitespace-nowrap"
            >
              {" "}
              {copied ? "کپی شد!" : "کپی لینک"}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-5 pt-2 gap-5">
          <a
            href="https://www.instagram.com/direct/inbox/"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center items-center flex-col"
          >
            <BsInstagram />
            <p className="mt-2">اینستاگرام</p>
          </a>
          <TelegramShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsTelegram />
            <p>تلگرام</p>
          </TelegramShareButton>
          <WhatsappShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsWhatsapp />
            <p>واتساپ</p>
          </WhatsappShareButton>
          <a
            href={`sms:+15153755550&body=${url}`}
            className="flex justify-center items-center flex-col"
          >
            <BsEnvelope />
            <p>پیام</p>
          </a>
          <EmailShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsEnvelope />
            <p>ایمیل</p>
          </EmailShareButton>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        onClick={() => {
          setShare(false);
          if (setstop) {
            setstop(false);
          }
        }}
        className="fixed top-0 left-0 h-full w-full bg-[#00000070] z-40"
      ></div>
      <div className="fixed bottom-0 left-0 w-full bg-white p-3 rounded-t-[10px] z-50 text-[14px]">
        <div>
          <h2 className="text-[20px] font-[700] text-right">اشتراک گذاری</h2>
        </div>
        <div dir="ltr" className="flex gap-2 items-center w-full">
            <input disabled value={url} className="rounded border h-[42px] px-2 w-full"/>
          <button
            onClick={() => {
              setCopied(true);
              copy(url);
            }}
            className="border rounded font-bold px-3 py-2 my-4 whitespace-nowrap"
          >
            {" "}
            {copied ? "کپی شد!" : "کپی لینک"}
          </button>
        </div>
        <div className="grid grid-cols-5 pt-2">
          <a
            href="https://www.instagram.com/direct/inbox/"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center items-center flex-col"
          >
            <BsInstagram />
            <p className="mt-2">اینستاگرام</p>
          </a>
          <TelegramShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsTelegram />
            <p>تلگرام</p>
          </TelegramShareButton>
          <WhatsappShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsWhatsapp />
            <p>واتساپ</p>
          </WhatsappShareButton>
          <a
            href={`sms:+15153755550&body=${url}`}
            className="flex justify-center items-center flex-col"
          >
           <BsEnvelope />
            <p>پیام</p>
          </a>
          <EmailShareButton
            url={url}
            className="flex justify-center items-center flex-col"
          >
            <BsEnvelope />
            <p>ایمیل</p>
          </EmailShareButton>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
