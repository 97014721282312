import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="my-16">
      <div className="flex flex-col gap-7 justify-center items-center">
        <img className="max-w-[600px]" src="/20602785_6325252.svg" alt="Error" />
        <p className="text-[18px] lg:text-[24px] text-center px-5">
          متاسفانه در حال حاضر به دلیل نوسانات ناگهانی قیمت سایت فروشی ندارد
        </p>
      </div>
    </div>
  );
};

export default NotFound;
