import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Reveal from "../motion/Reveal";
import { CartContext } from "../../context/CartContext";
import { calculateGoldPrice } from "../../utils/getPrice";
import { priceComma } from "../../utils/priceComma";

const ProductCard = ({ data, white }) => {
  const { goldPrice } = useContext(CartContext);

  return (
    <Reveal height head>
      <Link
        to={"/products/" + data?._id}
        className={`${
          white
            ? " border-[#999]/70 shadow-[#999]/70 shadow-2xl "
            : "border-[#eee] shadow-[#b1b1b1] shadow"
        } brand_item-box text-center pb-3 h-full  rounded-[12px] border-[2px] overflow-hidden block hover:text-orange-500 hover:scale-105 duration-200`}
      >
        <div className="  bg-white">
          <img src={CDN_BASE_URL + data?.img} className="w-full p-2 p-lg-3 h-[170px] sm:h-[260px] lg:h-[260px] 2xl:h-[300px] object-cover" alt="" />
        </div>
        <div className="brand_detail-box">
          <h5 dir="rtl" className={`${white && "text-white"}`}>
            <span>
              {" "}
              {priceComma(
                calculateGoldPrice(goldPrice, data.weight, data.makingFee),
                2
              )}
            </span>{" "}
            تومان
          </h5>
          <h6
            className={`${
              white && "text-white"
            } mt-2 text-[14px] sm:text-[16px] px-4`}
          >
            {data?.name}
          </h6>
          <div className="px-3">

          <button className={`${!white && "text-white"} bg-[rgb(109,90,56)] py-2 rounded-sm w-full mt-3 `}>مشاهده محصول</button>
          </div>
        </div>
      </Link>
    </Reveal>
  );
};

export default ProductCard;
